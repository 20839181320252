import { useEffect, useState } from "react"
import { Modal } from "@mantine/core"
import NamdevcoModalContent from "./namdevco-modal-content"
import PlaceAnOrderTutorialContent from "./place-an-order-tutorial"
import moment from "moment"
import styled from "styled-components"
import { useLocalStorage } from "react-use"
/**
 * Displays popup on first visit to the website
 * @param {Number} burseDate expects the date as number
 * @returns
 */
export default function FirstTimePopup({ burstDate = "1651240131776" }) {
  const [popupSeen, setPopupSeen] = useLocalStorage("popupSeen", false)
  const [popupSeenDate, setPopupSeenDate] = useLocalStorage("popupSeenDate")

  const [isFirstTime, setIsFirstTime] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openTutorialModal, setOpenTutorialModal] = useState(false)
  let cacheBurstDate = toMoment(burstDate)

  function toMoment(dateString) {
    return moment(new Date(Number(dateString)))
  }

  useEffect(() => {
    const firstTimeCheck = () => {
      // read data from localStorage
      // let visited = sessionStorage.getItem("visited") === "true"
      // let visitedDate = sessionStorage.getItem("visitedDate")
      if (popupSeen && popupSeenDate) {
        let visitedMoment = toMoment(popupSeenDate)
        if (cacheBurstDate.isAfter(visitedMoment)) return false
      }
      return popupSeen
    }
    if (!firstTimeCheck()) {
      setPopupSeen(true)
      setPopupSeenDate(new Date().getTime())
      // sessionStorage.setItem("visited", true)
      // sessionStorage.setItem("visitedDate", new Date().getTime())
      setIsFirstTime(true)
      // if you have not visited then show modal
      setOpenModal(true)
    }
  }, [])

  const handleClose = () => setOpenModal(false)
  const handleCloseTutorial = () => setOpenTutorialModal(false)
  const handleSecondaryButton = () => {
    setOpenModal(false)
    setOpenTutorialModal(true)
  }
  return (
    <>
      {isFirstTime && (
        <>
          <Modal
            opened={openModal}
            onClose={handleClose}
            withCloseButton={false}
          >
            <Container>
              <NamdevcoModalContent
                handleClose={handleClose}
                handleSecondaryButton={handleSecondaryButton}
              />
            </Container>
          </Modal>
          <Modal
            opened={openTutorialModal}
            onClose={handleCloseTutorial}
            withCloseButton={false}
          >
            <PlaceAnOrderTutorialContent handleClose={handleCloseTutorial} />
          </Modal>
        </>
      )}
    </>
  )
}

const Container = styled.div`
  padding: 0 2drem;
`
