import React from 'react';
import styled from 'styled-components';
import { Dialog, DialogContent } from '@material-ui/core';
import Spacer from '../spacer';

export const Pill = ({ title }) => {
	return(
		<PillComponent>{title}</PillComponent>
	)
}

const Day = ({ label, open}) => {
	return(
		<DayComponent open={open}>{label}</DayComponent>
	)
}

const StoreDetailsModal = ({ store, handleOnClose, open }) => {

	if(store.error) return <div>Error Fetch Store Details</div>
	
	return ( 
		<Dialog
			open={open}
			onClose={() => handleOnClose(false)}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
		>
		<DialogContent
			style={{padding: 20}}
		>
			<Content>
				<LogoBox>
					<Logo src={store.logo} />
				</LogoBox>
				<Spacer size={10} />
				<Title>{store.name}</Title>
				<Spacer size={20} />
				<Description>
					{store.description}
				</Description>
				<Spacer size={30} />

				<DeliveryInfo>
					{/* { store.doesWalkin ? <Pill title='In-Store' /> : null} */}
					{ (store.doesWalkin || store.doesCurbside) ? <Pill title='Curbside' /> : null }
					{ store.doesDelivery ? store.onHiredDriverNetwork ? <Pill title='Delivery' /> : <Pill title={`Delivery $${store.deliveryFee}`} /> : null } 
				</DeliveryInfo>
				<Spacer size={20} />
				<OpeningDays>
					{store.openingDays?.map((day, index) => (
						<Day key={`${index}-${new Date()}`} label={day.label} open={day.open} />
					))}
				</OpeningDays>
			</Content>

		</DialogContent>
	</Dialog>
	 );
}
 
export default StoreDetailsModal;

const PillComponent = styled.div`
border: 1px solid #707070;
box-sizing: border-box;
padding: 7px 5px;
font-size: 16px;
color: #707070;
flex: 1;
`;

const DayComponent = styled.div`
width: 25px;
height: 25px;
padding: 15px;
border: 1px solid #707070;
background-color: ${({open}) => open ? '#707070' : 'white'};
color: ${({open}) => open ? 'white' : '#707070'};
display: flex;
justify-content: center;
align-items: center;
`;

const Content = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const LogoBox = styled.div`
	width: 80px;
	height: 80px;
	border-radius: 4px;
	border: 1px solid #7070700D;
`;

const Logo = styled.img`
width: 100%;
height: 100%;
object-fit: contain;
`;

const Title = styled.h1`
font-size: 34px;
font-weight: 500;
text-decoration: underline;
`;

const Description = styled.p`
	font-size: 18px;
	font-weight: 400;
	line-height: 1.3;
	color: #707070;
	max-width: 400px;
`;

const DeliveryInfo = styled.div`
display: grid;
grid-template-columns: repeat(3, 1fr);
align-items: center;
gap: 20px;
max-width: 400px;
margin-left: auto;
margin-right: auto;
`;

const OpeningDays = styled.div`
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	gap: 10px;
	width: 100%;
	justify-items: center;
`;