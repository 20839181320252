import { Container, Grid, useMediaQuery } from "@material-ui/core"
import { Button, Typography } from "@unqueue-dev-inc/unqueue-ui-web"
import { useGlobalState } from "context/GlobalContextProvider"
import { notifyWhenBack } from "lib/firebase"
import moment from "moment"
import { useRouter } from "next/router"
import React, { useState } from "react"

export default function StoreTurnedOffModal(props) {
  const { note, reOpenDate, open = true, companyId, date } = props
  const router = useRouter()
  const state = useGlobalState()
  const [notify, setNotify] = useState({
    text: "Notify me when they’re back",
    disabled: false,
  })
  const sm = useMediaQuery("(max-width:600px)")

  //Redirects to home
  const handleRedirect = () => {
    router.push("/")
  }

  const handleNotify = async () => {
    const { userData, userAuth } = state
    if (userData && userData.expoToken != null) {
      await notifyWhenBack(companyId, "tokens", userData.expoToken)
    } else if (userAuth && userAuth.email != null) {
      await notifyWhenBack(companyId, "emails", userAuth.email)
    } else {
      var email = prompt(
        "Please enter an email so we can notify you when store reopens"
      )
      await notifyWhenBack(companyId, "emails", email)
    }

    setNotify({
      text: "You will be notified when this store is back on",
      disabled: true,
    })
  }
  return (
    <Container maxWidth="sm" style={{ marginTop: 36 }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={12} style={{ textAlign: "center", width: "100%" }}>
          <Typography
            variant="h1"
            style={{ fontSize: sm ? 48 : 66, fontFamily: "Cortado" }}
          >
            This store is temporarily
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center", width: "100%" }}>
          <img
            alt="store closed icon"
            style={{
              maxWidth: sm ? 200 : 280,
              width: "100%",
              height: "auto",
              transform: "rotate(10deg)",
              paddingBottom: 20,
            }}
            id="sign"
            src="/store_closed_sign.png"
          />
        </Grid>
        {note && (
          <React.Fragment>
            <Grid item xs={12}>
              <Typography variant="h3" style={{ fontSize: 22 }}>
                They wanted you to know:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body" style={{ fontSize: 20 }}>
                {note}
              </Typography>
            </Grid>
          </React.Fragment>
        )}
        {reOpenDate && (
          <Grid item xs={12}>
            <Typography variant="h3" style={{ fontSize: 22 }} gutter>
              Date expected back:{" "}
              <u>
                {moment(new Date(reOpenDate._seconds * 1000)).format(
                  "MMMM Do, YYYY"
                )}
                .
              </u>
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} style={{ textAlign: "center", width: "100%" }}>
          <Button
            variant="contained"
            onClick={() => handleNotify()}
            disabled={notify.disabled}
          >
            {notify.text}
          </Button>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center", width: "100%" }}>
          <Button outline onClick={() => handleRedirect()}>
            Browse similar stores
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
