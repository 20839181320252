import { Checkbox, Accordion } from "@mantine/core"
import styled from "styled-components"
import { Typography } from "@unqueue-dev-inc/unqueue-ui-web"
import { useMemo } from "react"
import { useMedia } from "react-use"

const testData = [
  { id: 1, name: "Fresh Produce", checked: true },
  { id: 2, name: "Frozen Produce", checked: false },
]

function TagList({
  tagsToFilterBy = [],
  initialTags = testData,
  setList = () => {},
}) {
  const isMobile = useMedia("(max-width: 650px)")
  const tags = useMemo(() => {
    return initialTags.map((el, index) => ({
      name: el,
      checked: false,
      id: index,
    }))
  }, [initialTags])

  const handleChange = tag => {
    const currentTagFilters = [...tagsToFilterBy]
    if (currentTagFilters.includes(tag.name)) {
      currentTagFilters.splice(currentTagFilters.indexOf(tag.name), 1)
    } else {
      currentTagFilters.push(tag.name)
    }

    setList([...currentTagFilters])
  }
  if (isMobile) {
    return (
      <div>
        {tags.length > 0 && (
          <>
            {/* <Typography variant="subtitle">Filter by tags</Typography> */}
            <Accordion
              styles={{
                label: {
                  fontWeight: 500,
                  fontFamily: "PhantomSans",
                  fontSize: "1.1rem",
                },
              }}
            >
              <Accordion.Item label="Filter by tags">
                {tags.map(item => (
                  <Row key={item.id}>
                    <Typography variant="body">{item.name}</Typography>
                    <Checkbox
                      checked={tagsToFilterBy.includes(item.name)}
                      onChange={() => handleChange(item)}
                      style={{ cursor: "pointer" }}
                    />
                  </Row>
                ))}
              </Accordion.Item>
            </Accordion>
          </>
        )}
      </div>
    )
  }
  return (
    <div>
      {tags.length > 0 && (
        <>
          <Typography variant="subtitle">Filter by tags</Typography>
          {tags.map(item => (
            <Row key={item.id}>
              <Typography style={{ fontSize: "1.1rem" }} variant="body">
                {item.name}
              </Typography>
              <Checkbox
                checked={tagsToFilterBy.includes(item.name)}
                onChange={() => handleChange(item)}
                style={{ cursor: "pointer" }}
              />
            </Row>
          ))}
        </>
      )}
    </div>
  )
}

export default TagList

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.2rem;
`
