import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Modal } from "@mantine/core"
import { Typography, TextInput, Button } from "@unqueue-dev-inc/unqueue-ui-web"
import Counter from "components/shared/counter"
import Spacer from "components/spacer"
import Image from "next/image"
import { Flex } from "components/layout/shared"
import { colors } from "styles/colors"
import toast from "react-hot-toast"
import { addWaitListEntry } from "lib/firebase"
import { motion } from "framer-motion"
import usePricing from "custom-hooks/usePricing"
import { TRACKING_EVENTS, _trackEvent } from "lib/mixpanel"

export default function KOSWaitListRegistration({
  open,
  setOpen,
  product,
  storeId,
}) {
  const { currentCurrency } = usePricing()
  const [count, setCount] = useState(1)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setCount(1)
    setLoading(false)
    setOpen(false)
    setHasSubmitted(false)
  }

  // Cannot reserve more than 100 books
  useEffect(() => {
    if (count > 100) {
      toast("You can only reserve up to 100 books.", {
        id: "reserveLimit",
      })
      setCount(100)
    }
  }, [count])

  const handleSaveToWaitList = async event => {
    // Get the form data
    event.preventDefault()
    const data = new FormData(event.target)
    const formObject = Object.fromEntries(data.entries())

    // Validation
    if (!formObject.email || !formObject.firstName || !formObject.lastName) {
      toast.error("Please fill all available fields")
      return
    }

    if (count < 1) {
      toast.error("You must reserve at least one copy of the book")
      return
    }

    setLoading(true)

    try {
      // Ready to submit
      const dataToSubmit = {
        companyId: storeId,
        email: formObject.email,
        firstName: formObject.firstName.trim(),
        lastName: formObject.lastName.trim(),
        name: `${formObject.firstName.trim()} ${formObject.lastName.trim()}`,
        productId: product?.id ?? "",
        count,
        items: `${product?.title}`,
        currency: currentCurrency,
      }
      await addWaitListEntry(dataToSubmit)
      _trackEvent(TRACKING_EVENTS.SUCCESSFUL_BOOK_RESERVE)
      setHasSubmitted(true)
    } catch (err) {
      toast.error(err.message)
      return
    } finally {
      setLoading(false)
    }
  }

  if (hasSubmitted) {
    return (
      <Modal
        opened={open}
        onClose={handleClose}
        size="full"
        centered
        withCloseButton={false}
        classNames={{
          modal: "kos-modal",
        }}
      >
        <Media style={{ minHeight: "80vh", zIndex: 1 }}>
          <ImageContainer
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.4 }}
          >
            <Image
              src="/kos-branded-text.svg"
              objectFit="contain"
              layout="fill"
            />
          </ImageContainer>
          <Spacer size={36} />
          <div
            style={{
              margin: "0 auto",
              maxWidth: 800,
              zIndex: 1,
            }}
          >
            <Button
              onClick={() => handleClose()}
              backgroundColor="white"
              color={colors.base}
            >
              Close
            </Button>
          </div>
          <div style={{ zIndex: 0 }}>
            <Image
              src="/kos-background-asset.svg"
              objectFit="cover"
              layout="fill"
            />
          </div>
        </Media>
      </Modal>
    )
  }
  return (
    <Modal
      opened={open}
      onClose={handleClose}
      size="full"
      centered
      withCloseButton={false}
      classNames={{
        modal: "kos-modal",
      }}
    >
      <Grid>
        <Media>
          <ImageContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            style={{ width: "100%", height: "100%", maxWidth: 400 }}
          >
            <Image src="/kos-reserve.svg" objectFit="contain" layout="fill" />
          </ImageContainer>
        </Media>
        <Content>
          <Typography
            style={{ fontSize: "1.5rem", maxWidth: "40ch" }}
            variant="h3"
          >
            Reserve your books by entering your information below.
          </Typography>
          <Spacer size={24} />
          <form onSubmit={handleSaveToWaitList}>
            <TextInput
              id="standard-basic"
              name="firstName"
              placeholder="Enter your first name"
              label="First Name"
              type="text"
            />
            <Spacer size={8} />
            <TextInput
              id="standard-basic"
              name="lastName"
              placeholder="Enter your last name"
              label="Last Name"
              type="text"
            />
            <Spacer size={8} />
            <TextInput
              id="standard-basic"
              name="email"
              placeholder="Enter your email address"
              label="Email Address"
              type="email"
              helperText="This is where we will notify you about your order and the book’s release date."
            />
            <Spacer size={2} />
            <Flex justify="space-between">
              <Typography style={{ fontWeight: 500 }} variant="body">
                Number of copies
              </Typography>
              <CounterContainer count={count} setCount={setCount} />
            </Flex>
            <Spacer size={24} />
            <Button loading={loading} type="submit">
              Confirm your reservation
            </Button>
          </form>
        </Content>
      </Grid>
    </Modal>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`
const Media = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 60vh;
  background: ${colors.kosPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px;

  @media (max-width: 700px) {
    min-height: 35vh;
    padding: 30px;
  }
`
const Content = styled.div`
  padding: 5%;

  @media (max-width: 700px) {
    padding: 3rem 5%;
  }
`
const CounterContainer = styled(Counter)`
  /* flex-grow: 1; */
`
const ImageContainer = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 40vh;
`
