import React from "react"
import styled, { keyframes } from "styled-components"
import { TiArrowUp } from "react-icons/ti"
import { colors } from "styles/colors"

const enter = keyframes`
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`

const Container = styled.div`
  width: 180px;
  position: fixed;
  z-index: 98;
  bottom: 30px;
  right: 30px;
  background: ${colors.base};
  display: flex;
  justify-content: center;
  border-radius: 90px;
  padding: 10px;
  cursor: pointer;
  opacity: 0;
  transform: translateY(10px);

  animation: ${enter} 0.4s ease-out forwards;
  @media (max-width: 500px) {
    bottom: 20px;
    right: 10px;
  }
`

const Text = styled.p`
  margin: 0;
  /* font-family: "changa"; */
  color: ${colors.shopperPrimary};
`

const BackToTop = ({ handleClickEvent }) => (
  <Container onClick={() => handleClickEvent()}>
    <Text>
      Back to Top <TiArrowUp style={{ marginBottom: 3 }} size={28} />
    </Text>
  </Container>
)

export default BackToTop
