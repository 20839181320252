import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import { Button, Typography } from "@unqueue-dev-inc/unqueue-ui-web"
import { motion, AnimatePresence } from "framer-motion"
import { FaChevronDown, FaChevronUp } from "react-icons/fa"
import Spacer from "components/spacer"
const StoreProductItemList = ({
  data,
  handleOnClick,
  isCustomCheckout,
  ProductComponent,
  categoryToScrollTo
}) => {
  const [showMore, setShowMore] = useState(false)
  const [products, setProducts] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const title = useRef(null) // ref for the category title element, used for scrolling
  const limit = 4
  const atTop = useRef(false)
  const [pushRight, setPushRight] = useState(false)
  const MotionProductComponent = motion(ProductComponent)

  useEffect(() => {
    // if there are more than 4 products show has more button
    setHasMore(data.length > limit)
    if (showMore) {
      setProducts(data)
      return
    }
    setProducts(data.slice(0, limit))
  }, [showMore, data])

  useEffect(() => {
    // if this is category to scroll to then execute scroll
    if (`${products[0]?.categoryId}` === categoryToScrollTo){
      title.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }
  }, [categoryToScrollTo]);

  useEffect(() => {
    //don't set event listener if it is a custom checkout
    if (!isCustomCheckout) return

    //sets the pushRight to true if the title is at the top of the screen
    const handleScroll = () => {
      const distanceToTop = title.current.getBoundingClientRect().top
      if (distanceToTop < 100 && !atTop.current) {
        atTop.current = true
        setPushRight(true)
      } else if (distanceToTop > 100 && atTop.current) {
        atTop.current = false
        setPushRight(false)
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleShowMore = () => {
    // scroll lo the top of the category when clicking show less
    if (showMore)
      title.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    // toggle showMore
    setShowMore(!showMore)
  }

  return (
    <>
      <div ref={title} id={`${products[0]?.categoryId}`}>
        <Typography
          variant="h3"
          style={{
            marginBottom: "1rem",
            paddingTop: "3.5rem",
            paddingLeft: isCustomCheckout && pushRight ? "5rem" : "1rem",
            transition: "all .3s",
          }}
        >
          {products.length > 0 && products[0].categoryName}
        </Typography>
      </div>
      <AnimatePresence>
        <List>
          {products.map((item, index) => (
            <ProductComponent
              key={item.id}
              item={item}
              index={index}
              onClick={() => handleOnClick(item)}
            />
          ))}
        </List>
      </AnimatePresence>

      {/* if there are more than 4 products show has more button */}
      {hasMore && (
        <Centered>
          <Button
            onClick={handleShowMore}
            style={{
              marginTop: "2rem",
              paddingLeft: "1.25rem",
              paddingRight: "1.25rem",
              alignItems: "center",
              display: "flex",
              fontWeight: 500,
            }}
            small={true}
          >
            {showMore ? "See less " : "See more"}
            <Spacer size={5} axis="horizontal" />
            {showMore ? <FaChevronUp /> : <FaChevronDown />}
          </Button>
        </Centered>
      )}
    </>
  )
}

export default StoreProductItemList
const Centered = styled.div`
  display: flex;
  justify-content: center;
`
const List = styled.div`
  display: grid;
  grid-gap: 20px;
  row-gap: 50px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 550px) {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
    grid-gap: 10px;
    row-gap: 30px;
  }

  @media (max-width: 360px) {
    grid-template-columns: repeat(2, minmax(130px, 1fr));
    grid-gap: 10px;
    row-gap: 30px;
  }
`
