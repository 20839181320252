import { Button, Typography } from "@unqueue-dev-inc/unqueue-ui-web/"
import Spacer from "components/spacer"
import Lottie from "react-lottie"
import animationData from "../../animations/racially_ambiguious_farmer.json"
import styled from "styled-components"

const farmerAnimation = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

function NamdevcoModalContent({ handleClose, handleSecondaryButton }) {
  return (
    <>
      <Spacer size="1rem" />
      <Centered>
        <Typography variant="h4" style={{ fontSize: "20px" }}>
          Welcome to the NAMDEVCO
        </Typography>
        <Typography variant="h4" style={{ fontSize: "20px" }}>
          Farmers&apos; Market
        </Typography>
        <Typography variant="h4" style={{ fontSize: "20px" }}>
          Queen&apos;s Park Savannah, Port of Spain
        </Typography>
      </Centered>
      <Animation>
        <Lottie
          options={farmerAnimation}
          height={"60%"}
          width={"60%"}
          isStopped={false}
          isPaused={false}
          isClickToPauseDisabled={true}
        />
      </Animation>
      <Typography variant="body" style={{ fontSize: "1.1rem" }}>
        Unqueue has teamed up with NAMDEVCO to bring the popular QPS Saturday
        morning farmers&apos; market to you online. Shop from scores of vendors
        from the comfort of home; have produce, baked goods, plants and more
        delivered same day to your doorstep, safely and conveniently.
      </Typography>
      <Spacer size="1rem" />
      <Typography variant="body" style={{ fontSize: "1.1rem" }}>
        The Unqueue NAMDEVCO Farmers&apos; Market is live online starting the
        day before the market, Friday, from 12pm, through to 6am on Saturday
        mornings. Under this partnership, vendors receive 100% of the value of
        the goods you purchase from them online.
      </Typography>
      <Spacer size="1.5rem" />
      <Button onClick={handleClose}>Start shopping</Button>
      <Spacer size=".5rem" />
      <Button outline onClick={handleSecondaryButton}>
        Learn how to place an order
      </Button>
    </>
  )
}

export default NamdevcoModalContent

const Centered = styled.div`
  text-align: center;
`
const Animation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
