import { Button, Typography } from "@unqueue-dev-inc/unqueue-ui-web/"
import Spacer from "components/spacer"
import styled from "styled-components"

function PlaceAnOrderTutorialContent({ handleClose }) {
  return (
    <>
      <Spacer size="1rem" />
      <Centered>
        <Typography variant="subitile" style={{ fontSize: "20px" }}>
          Placing an order on Unqueue
        </Typography>
      </Centered>
      <Spacer size={20} />
      <Animation>
        <div style={{ height: "60vh" }}>
          <iframe
            width={"100%"}
            height={"100%"}
            src="https://www.loom.com/embed/060f442ba3f04cb9b020454720c0a305?hide_share=true&hide_title=true&autoplay=true"
            title="Placing an order on Unqueue"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </div>
      </Animation>

      <Spacer size="1.5rem" />
      <Button onClick={handleClose}>Start shopping</Button>
      <Spacer size=".5rem" />
    </>
  )
}

export default PlaceAnOrderTutorialContent

const Centered = styled.div`
  text-align: center;
`
const Animation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
