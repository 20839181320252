import React from 'react';
import styled from 'styled-components';

const sizes = {
	'sm': '600px',
	'md': '800px',
	'lg': '1200px'
}

const MaxWidthContainer = ({ size, children }) => {
	return ( 
		<Container size={size}>
			{children}
		</Container>
	 );
}
 
export default MaxWidthContainer;

const Container = styled.div`
position: relative;
max-width: ${({ size }) => sizes[size]};
margin-left: auto;
margin-right: auto;
`;