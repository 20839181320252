import { Dialog, DialogContent } from "@material-ui/core"
import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import SearchInput from "components/search-input"
import Spacer from "components/spacer"
import { IoIosClose } from "react-icons/io"
import { Flex } from "components/layout/shared"
import ProductList from "components/search/product-list"
import { _filterForOnlineCompanies } from "lib/utils"
import ResultPriorities from "./result-priorities"
import { Typography } from "@unqueue-dev-inc/unqueue-ui-web"
import TagList from "./tag-list"
import { motion, AnimatePresence } from "framer-motion"
import { UnstyledButton } from "styles/buttons"
import { BiSearchAlt } from "react-icons/bi"
import { TRACKING_EVENTS, _trackEvent } from "lib/mixpanel"
import Image from "next/image"

const PRIORITY = {
  PRODUCT: "product",
  CATEGORY: "category",
}

export default function SearchModal({
  open,
  setOpen,
  storeDetails,
  categories = [],
  products = [],
  scrollToCategory,
  openGlobalSearchModal,
}) {
  const [search, setSearch] = useState("")
  const [hasLoaded, setHasLoaded] = useState(false)
  const [priority, setPriority] = useState(PRIORITY.PRODUCT)
  const [productsByCategory, setProductsByCategory] = useState({})
  const [filteredCategories, setFilteredCategories] = useState(categories)
  const [filteredProducts, setFilteredProducts] = useState(products)
  const [tagsToFilterBy, setTagsToFilterBy] = useState([])

  const tags = useMemo(() => {
    const tags = products.map(el => el.tags ?? [])
    const tagsWithData = tags.filter(el => el.length > 0).flat()
    const uniqueTags = [...new Set(tagsWithData)]
    return uniqueTags
  }, [products.length])

  useEffect(() => {
    setFilteredCategories(categories)
  }, [categories.length])

  // This is the problematic effect
  useEffect(() => {
    if (products === undefined || !products?.length) return
    let reducer = (prev, curr) => {
      if (prev[curr.categoryId] === undefined) prev[curr.categoryId] = []
      prev[curr.categoryId].push(curr)
      return prev
    }
    setProductsByCategory(products.reduce(reducer, {}))
    setFilteredProducts(products)
  }, [products.length])

  useEffect(() => {
    setSearch("")
    filterResults("")
  }, [priority])

  useEffect(() => {
    filterResults(search)
  }, [tagsToFilterBy?.length, search])

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpenGlobalSearch = () => {
    _trackEvent(TRACKING_EVENTS.CLICKED_GLOBAL_SEARCH_FROM_STORE_SEARCH, {
      store: storeDetails?.name,
    })
    handleClose()
    openGlobalSearchModal()
  }

  const filterResults = value => {
    if (priority === PRIORITY.CATEGORY) {
      let filtered = categories.filter(category => {
        let title = category?.title?.toLowerCase() || ""
        return title.includes(value.toLowerCase())
      })
      setFilteredCategories(value.trim() === "" ? categories : filtered)
    }
    if (priority === PRIORITY.PRODUCT) {
      let filtered = products.filter(product => {
        let title = product?.title?.toLowerCase() || ""
        return title.includes(value.toLowerCase())
      })
      if (tagsToFilterBy.length > 0) {
        // Filter for products that contain at least one of the tags to filter by
        filtered = filtered.filter(product => {
          let tags = product?.tags ?? []
          return tagsToFilterBy.some(tag => tags.includes(tag))
        })
      }
      setFilteredProducts(
        tagsToFilterBy.length > 0
          ? filtered
          : value.trim() === ""
          ? products
          : filtered
      )
    }
  }

  const onSearchChange = event => {
    let value = event.target.value
    setSearch(value)
    filterResults(value)
  }
  return (
    <Dialog fullScreen open={open} onClose={handleClose} maxWidth="md">
      <DialogContent style={{ padding: 0 }}>
        <Container>
          <HeaderSection>
            <CloseContainer
              style={{ padding: "0 20px" }}
              align="center"
              justify="flex-end"
            >
              <Close>
                <CloseButton onClick={() => handleClose()}>
                  <IoIosClose size={35} />
                </CloseButton>
              </Close>
            </CloseContainer>
            <SearchSection>
              <SearchInput
                autoFocus
                onChange={onSearchChange}
                placeholder="Enter a search term here"
                value={search}
              />
            </SearchSection>
          </HeaderSection>
          <Grid>
            <Sidebar>
              <Image
                src={storeDetails?.logo}
                width={50}
                height={50}
                objectFit="cover"
                alt="company logo"
              />
              <Spacer size={20} />
              <Title>
                Search {storeDetails.name}{" "}
                {storeDetails?.name?.toLowerCase() === "namdevco"
                  ? ` Stalls`
                  : ""}
              </Title>
              <UnstyledButton onClick={handleOpenGlobalSearch}>
                <Flex align="center" direction="row">
                  <BiSearchAlt size={20} />
                  <Spacer size={8} />
                  <Typography
                    style={{
                      fontSize: 18,
                      textDecoration: "underline",
                      textAlign: "left",
                    }}
                    variant="body"
                  >
                    Search all of Unqueue instead
                  </Typography>
                </Flex>
              </UnstyledButton>

              <Spacer size={30} />
              <ResultPriorities value={priority} setValue={setPriority} />
              <Spacer size={30} />
              {priority === PRIORITY.PRODUCT && (
                <TagList
                  initialTags={tags}
                  tagsToFilterBy={tagsToFilterBy}
                  setList={setTagsToFilterBy}
                />
              )}
            </Sidebar>
            <Right>
              <Spacer size={40} />
              <div>
                <Typography variant="h3">
                  {priority === PRIORITY.CATEGORY
                    ? `Showing all categories`
                    : `Products`}
                </Typography>
              </div>
              {priority === PRIORITY.PRODUCT && (
                <div>
                  <Typography variant="h4">{`${filteredProducts.length} ${
                    filteredProducts.length > 1 ? "Results" : "Result"
                  }`}</Typography>
                </div>
              )}
              <Spacer size={20} />
              {priority === PRIORITY.CATEGORY && (
                <AnimatePresence>
                  {filteredCategories.map((category, index) => (
                    <motion.div
                      key={category.id}
                      id={category.id}
                      layout={true}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{
                        delay: index * 0.01,
                        layout: {
                          duration: 0.6,
                          ease: [0.22, 1, 0.36, 1],
                        },
                      }}
                      exit={{ opacity: 0 }}
                    >
                      <Row onClick={() => scrollToCategory(category.id)}>
                        <Typography variant="body">{category.title}</Typography>
                        <Typography
                          style={{ whiteSpace: "nowrap" }}
                          variant="body"
                        >
                          {productsByCategory[category.id]?.length
                            ? `${productsByCategory[category.id]?.length}`
                            : `0`}{" "}
                          Products
                        </Typography>
                      </Row>
                    </motion.div>
                  ))}
                </AnimatePresence>
              )}
              {priority === PRIORITY.PRODUCT && (
                <>
                  {filteredProducts.length ? (
                    <ProductList products={filteredProducts} />
                  ) : null}
                </>
              )}
            </Right>
          </Grid>
        </Container>
      </DialogContent>
    </Dialog>
  )
}

const Container = styled.div`
  background: white;
  padding: 0 0 80px 0;
  position: relative;
`
const CloseContainer = styled(Flex)`
  order: 1;
  @media (max-width: 650px) {
    width: 100%;
    order: 0;
  }
`
const Right = styled.div`
  min-width: 100%;
  padding: 0 0.5rem;
  @media (min-width: 650px) {
    padding-left: 1rem;
  }
`
const Close = styled.div`
  cursor: pointer;
  height: 40px;
  width: 40px;
  transition: 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 23px;
  :hover {
    background: ${props => (props.black ? "#999" : "#fafafa")};
  }
`
const CloseButton = styled.button`
  background-color: #eee;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  z-index: 2;
  @media (max-width: 650px) {
  }
`
const Grid = styled.section`
  display: grid;
  grid-template-columns: 300px 1fr;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
  align-items: start;
  height: auto;
  overflow: initial;

  @media (max-width: 650px) {
    grid-template-columns: 100%;
  }
`
const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  max-width: 1200px;
  margin: 0 auto;
  background: white;
  z-index: 4;
  padding-top: 20px;
  position: sticky;
  top: 0;

  @media (max-width: 650px) {
    flex-direction: column;
    justify-content: center;
  }
`
const Title = styled.h3`
  font-size: 30px;
  margin-bottom: 15px;
`
const SearchSection = styled(Flex)``
const StoreContainer = styled.div`
  padding: 0 15px;
  margin-top: 1rem;
`
const Sidebar = styled.div`
  padding: 0 15px;
  position: sticky;
  align-self: start;
  top: 85px;

  @media (max-width: 650px) {
    position: relative;
    top: unset;
  }
`
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  font-size: 20px;
  &:hover {
    background-color: #eaeaea;
    cursor: pointer;
  }
`
