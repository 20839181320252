import React from "react"
import Skeleton from "react-loading-skeleton"
import styled from "styled-components"
import { FaSearch } from "react-icons/fa"

const StoreHeader = ({
  customCheckout,
  companyId,
  handleOnClick,
  placeholderHeaderImage,
  handleOpenSearchModal,
  ...otherProps
}) => {
  // console.log({ otherProps })

  const {
    acceptsCard,
    acceptsCash,
    acceptsPayouts,
    acceptsPortableCard,
    doesCurbside,
    doesDelivery,
    doesInternationalShipping,
    doesWalkin,
    headerImage,
    logo,
    name,
    onHiredDriverNetwork,
  } = otherProps ?? {}
  const renderPickupText = () => {
    // International Shipping
    if (doesInternationalShipping) {
      return `Ships Worldwide!`
    }
    //Nationwide delivery
    if (doesDelivery && onHiredDriverNetwork) {
      return `🚗 Delivery anywhere in Trinidad`
    }
    if (doesDelivery) {
      return `🚗 Delivery available`
    }
    return ``
  }

  return (
    <Container>
      <ImageContainer noHeader={!headerImage}>
        <Banner src={headerImage ?? placeholderHeaderImage} />
        {!customCheckout && <Logo src={logo} />}
      </ImageContainer>
      {!customCheckout && (
        <Details>
          <DeliveryBox>
            <Text>{renderPickupText()}</Text>
          </DeliveryBox>
          <StoreNameBox onClick={() => handleOnClick(true)}>
            <HeaderText>{name ? name : <Skeleton />}</HeaderText>
          </StoreNameBox>
          <PaymentOptions>
            {acceptsCash ? (
              <PaymentOption>
                <Icon src={"/cash.svg"} />
                <IconLabel>Cash</IconLabel>
              </PaymentOption>
            ) : (
              []
            )}
            {acceptsCard || acceptsPortableCard ? (
              <PaymentOption>
                <Icon src={"/linx.svg"} />
                <IconLabel>Linx</IconLabel>
              </PaymentOption>
            ) : (
              []
            )}
            {acceptsPayouts ? (
              <PaymentOption>
                <Icon style={{ width: 27 }} src={"/pay_online_icon.svg"} />
                <IconLabel>Pay Online</IconLabel>
              </PaymentOption>
            ) : (
              []
            )}
          </PaymentOptions>
        </Details>
      )}
    </Container>
  )
}

export default StoreHeader

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const ImageContainer = styled.div`
  width: 100%;
  height: ${props => (props.noHeader ? "20vh" : "30vh")};
`
const SearchContainer = styled.div`
  position: absolute;
  bottom: ${props => (!props.isCustomCheckout ? "5rem" : "1.5rem")};
  right: 2.5rem;
  cursor: pointer;
`
const SearchButton = styled.div`
  background: white;
  border: none;
  outline: none;
  padding: 0.5rem;
  border-radius: 5px;

  span {
    font-size: 20px;
    color: rgb(84, 84, 84);
    margin-right: 10px;
  }
`
const Banner = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #eee;
`

const Logo = styled.img`
  display: block;
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: -40px;
  border: 2px solid #eee;
  border-radius: 4px;
`

const Details = styled.div`
  padding: 40px 30px 0;
  /* padding-top: 40px; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 20px;

  @media (max-width: 550px) {
    grid-template-columns: 100%;
    gap: 10px;
  }
`

const CenteredText = styled.p`
  text-align: center;
`

const Text = styled(CenteredText)`
  color: #707070;
  font-size: 18px;
  line-height: 22px;
`

const DeliveryBox = styled.div`
  justify-self: flex-start;
  @media (max-width: 550px) {
    order: 1;
    justify-self: center;
  }
`

const StoreNameBox = styled.div`
  cursor: pointer;
  @media (max-width: 550px) {
    order: 0;
  }
`

const Icon = styled.img`
  width: 30px;
`

const PaymentOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: center;
  gap: 15px;
  justify-self: flex-end;

  @media (max-width: 550px) {
    justify-self: center;
  }
`

const IconLabel = styled.span`
  margin-left: 4px;
`
const PaymentOption = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  gap: 2px;
  align-items: center;
  align-content: center;
  opacity: 0.8;
`

const HeaderText = styled(CenteredText)`
  font-size: 36px;
  line-height: 1.2;
  color: #21262e;
  text-decoration: underline;

  @media (max-width: 768px) {
    font-size: 25px;
  }
`

const LightText = styled(CenteredText)`
  align-items: center;
`
