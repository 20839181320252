import usePricing from "custom-hooks/usePricing"
import Image from "next/image"
import React from "react"
import styled from "styled-components"
import { colors } from "styles/colors"
import { motion } from "framer-motion"
import useProductImage from "custom-hooks/useProductImage"
import useHeaderImage from "custom-hooks/useHeaderImage"
// import { numberToCurrency, toCurrency } from "../../lib/utils"

const StoreProductItem = ({ item, onClick, index = 0 }) => {
  const { numberToCurrency } = usePricing()
  const {
    image,
    title,
    price,
    onSale,
    salePrice,
    isPreSale,
    isWaitListProduct,
    companyCategories = [],
    companyId,
  } = item

  const placeholderImage = useHeaderImage("H9ToSaLhx7liC21nrXnW")

  return (
    <Container
      key={item.id}
      layout={true}
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        delay: index * 0.05,
      }}
      exit={{ opacity: 0 }}
    >
      <ImageContainer>
        <Image
          src={
            image
              ? image
              : companyId === "ckpouMAnzDX1Q7uoaH84kv1quvc2" ||
                companyId === "SsVdsM0ogkgpbzO8QLH4oSVqnYs2"
              ? placeholderImage
              : "/product-placeholder.svg"
          }
          alt={title}
          objectFit="cover"
          layout="fill"
        />
        {onSale ? <TagLabel>Sale!</TagLabel> : null}
        {isPreSale ? (
          <TagLabel color={colors.preOrder}>Pre-Sale</TagLabel>
        ) : null}
        {isWaitListProduct ? (
          <TagLabel color={colors.preOrder}>Order Now</TagLabel>
        ) : null}
      </ImageContainer>
      <TextBox>
        <Title>{title}</Title>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Price onSale={onSale ? true : undefined}>
            {numberToCurrency(price)}
          </Price>
          {onSale ? <SalePrice>{numberToCurrency(salePrice)}</SalePrice> : null}
        </div>
      </TextBox>
    </Container>
  )
}

export default StoreProductItem

const Container = styled(motion.div)`
  color: #21262e;
  cursor: pointer;
  position: relative;
`

const ImageContainer = styled.div`
  width: 100%;
  height: 280px;
  object-fit: cover;
  object-position: center;
  background-color: #eee;
  position: relative;

  @media (max-width: 1220px) {
    height: 350px;
  }

  @media (max-width: 980px) {
    height: 300px;
  }

  @media (max-width: 940px) {
    height: 380px;
  }

  @media (max-width: 550px) {
    height: 160px;
  }
`

const TagLabel = styled.div`
  position: absolute;
  width: fit-content;
  font-size: 18px;
  padding: 6px 10px;
  top: -10px;
  right: -10px;
  background-color: ${props => props.color ?? colors.sale};
  color: ${props => (props.color ? "black" : "#fff")};
  text-align: center;
  font-weight: 500;
  font-family: "PhantomSans";
  text-transform: uppercase;
  white-space: no-wrap;

  @media (max-width: 550px) {
    font-size: 14px;
    padding: 6px 5px;
    right: -5px;
  }
`

const TextBox = styled.div`
  margin-top: 12px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding: 0;
  box-sizing: border-box;
`

const Title = styled.h1`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
`

const Price = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  text-decoration: ${({ onSale }) => (onSale ? "line-through" : "none")};
`
const SalePrice = styled.p`
  color: ${colors.sale};
  margin-left: 5px;
`
