import { Dialog, DialogContent, DialogTitle } from "@material-ui/core"
import { Flex } from "components/layout/shared"
import React from "react"
import styled from "styled-components"
import { IoIosClose } from "react-icons/io"
import { MainButton } from "styles/buttons"
import * as actions from "../../context/actionTypes"
import { useMedia } from "react-use"
import {
  useGlobalDisptach,
  useGlobalState,
} from "context/GlobalContextProvider"
import { Button } from "@unqueue-dev-inc/unqueue-ui-web"

export default function NewOrderConfirmationDialog({
  newItemToAdd,
  open,
  setOpen,
  startNewOrder,
}) {
  const isMobile = useMedia("(max-width: 768px)")
  const state = useGlobalState()
  const dispatch = useGlobalDisptach()

  const handleClose = () => {
    setOpen(false)
  }

  const handleStartNewOrder = () => {
    dispatch({
      type: actions.CLEAR_CART,
    })
    requestAnimationFrame(() => {
      startNewOrder(newItemToAdd)
    })
    requestAnimationFrame(() => {
      handleClose()
    })
  }

  return (
    <Dialog
      fullScreen={isMobile ? true : false}
      open={open}
      onClose={handleClose}
      maxWidth="sm"
    >
      <DialogTitle>
        <CloseContainer
          style={{ padding: "0 20px" }}
          align="center"
          justify="flex-end"
        >
          <Close>
            <CloseButton onClick={() => handleClose()}>
              <IoIosClose size={35} />
            </CloseButton>
          </Close>
        </CloseContainer>
      </DialogTitle>
      <DialogContent style={{ padding: "30px" }}>
        <Title>Create a new order?</Title>
        <Copy>
          Your order contains items from {state.shoppingCart[0]?.storeName}.
          Create a new order to add items from {state.currentStoreDetails.name}
        </Copy>
        <Button onClick={() => handleStartNewOrder()}>Start New Order</Button>
      </DialogContent>
    </Dialog>
  )
}

const CloseContainer = styled(Flex)`
  order: 1;
  @media (max-width: 650px) {
    width: 100%;
    order: 0;
  }
`
const Close = styled.div`
  cursor: pointer;
  height: 40px;
  width: 40px;
  transition: 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 23px;
  :hover {
    background: ${props => (props.black ? "#999" : "#fafafa")};
  }
`
const CloseButton = styled.button`
  background-color: #eee;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  z-index: 2;
  @media (max-width: 650px) {
  }
`
const Title = styled.h3`
  font-size: 35px;
  margin-bottom: 20px;
`
const Copy = styled.p`
  font-size: 22px;
  line-height: 1.2;
  margin-bottom: 30px;
  opacity: 0.8;
`
