import { Typography } from "@unqueue-dev-inc/unqueue-ui-web"
import { SegmentedControl, Center, Box, MantineProvider } from "@mantine/core"
import { FaStore, FaBoxes } from "react-icons/fa"
import styled from "styled-components"
import Spacer from "components/spacer"
function ResultPriorities({ value, setValue }) {
  return (
    <div>
      <MantineProvider
        theme={{
          colors: {
            custom: [
              "#009cc7",
              "#009cc7",
              "#009cc7",
              "#009cc7",
              "#009cc7",
              "#009cc7",
              "#009cc7",
              "#009cc7",
            ],
          },
        }}
      >
        <Typography variant="subtitle">Result Preference</Typography>
        <Spacer size={8} />
        <Container>
          <SegmentedControl
            value={value}
            onChange={setValue}
            size="sm"
            radius={30}
            color="custom"
            styles={{
              label: {
                fontFamily: "PhantomSans",
                fontSize: "1rem",
                fontWeight: 500,
              },
            }}
            data={[
              {
                value: "product",
                label: (
                  <Center sx={{ padding: ".2rem" }}>
                    <FaBoxes />
                    <Box ml={5} sx={{ fontWeight: "400" }}>
                      Product View
                    </Box>
                  </Center>
                ),
              },
              {
                value: "category",
                label: (
                  <Center sx={{ padding: ".2rem" }}>
                    <FaStore />
                    <Box ml={5} sx={{ fontWeight: "400" }}>
                      Category View
                    </Box>
                  </Center>
                ),
              },
            ]}
          />
        </Container>
      </MantineProvider>
    </div>
  )
}

export default ResultPriorities

const Container = styled.div`
  & label {
    margin-bottom: 0;
  }
`
